import React, { useState, useEffect } from "react";
import TextLoop from "react-text-loop";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Hidden } from "@material-ui/core";
import bannerImg from "../../../assets/img/banner1.png";
import LazyLoad from "react-lazyload";
import Gradient from "rgt";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bannerImg: {
    width: "100%",
  },
  heroContainer: {
    padding: " 2rem .75rem 0rem 2.25rem",
    [theme.breakpoints.down("xs")]: {
      padding: " 2rem .75rem 2rem 0.75rem",
      textAlign: "center",
    },
  },
  textContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: " 2rem .75rem 0rem 0.75rem",
      alignItems: "center",
    },
  },
  heading: {
    fontSize: "3.5rem",
    lineHeight: "4rem",
    ...theme.typography.introText,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem",
      lineHeight: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
    },
    // [theme.breakpoints.down("sm")]:{
    //   fontSize:"1.5rem",
    //   lineHeight:"2rem"
    // }
  },
  subHeading: {
    fontSize: "1.5rem",
    fontWeight: "300",
    marginTop: "2rem",
    ...theme.typography.introText,
    // marginRight:"14rem",
    marginBottom: "2.75rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem",
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
      fontSize: "1rem",
      marginTop: "1rem",
    },
  },
  tHeading: {
    ...theme.typography.introText,
    fontWeight: 500,
    fontSize: "2.75rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  button: {
    // marginTop:"2rem",
    // marginBottom:"2.5rem",

    background: "linear-gradient(90deg, #FF827F 5.38%, #FEE1A7 91.77%)",
    borderRadius: "40px",
    padding: "1.125rem 4.25rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    width: "fit-content",
    // fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      padding: "0.75rem 3rem",
      marginTop: "0.5rem",
    },
  },
  buttonText: {
    fontWeight: 400,
    fontSize: "1.25rem",
    ...theme.typography.introText,
    lineHeight: "1.25rem",
    texTransform: "uppercase",
    color: "rgba(11,18,35,1)",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}));

const Hero = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.heroContainer}>
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={5}
          lg={5}
          justify="center"
        >
          {/* <Grid item  xs={2}></Grid>  */}
          <Grid
            item
            container
            className={classes.textContainer}
            direction="column"
            justify="center"
          >
            <Typography className={classes.heading} variant="h3">
              <Gradient dir="left-to-right" from="#007CF0" to="#00DFD8">
                Know {""}
              </Gradient>
              <TextLoop>
                <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                  Friends{" "}
                </Gradient>
                <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                  Family{" "}
                </Gradient>
                <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                  Parents
                </Gradient>
              </TextLoop>{" "}
              <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
                Better. {""}
              </Gradient>
            </Typography>
            <Typography className={classes.tHeading} variant="h4">
              Create and revisit memories.
            </Typography>
            <Typography className={classes.subHeading}>
              Nostalgyc is the first ever platform dedicated to make deeper
              level connections, with yourself or with people in your life. We
              aim to help you discover, save, and share important moments,
              events and thoughts from your life and the lives of people you
              care about.
            </Typography>
            <Typography className={classes.tHeading}>
              <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                Try Nostalgyc App today!
              </Gradient>
            </Typography>
            <Button
              href="https://airtable.com/appiGkbUurgJkpkzY/shrdNb0uIGLaDc9T8"
              className={classes.button}
            >
              <Typography className={classes.buttonText}>Get Access</Typography>{" "}
            </Button>
          </Grid>
          {/* <Grid item xs={8}></Grid>         */}
        </Grid>
        <Hidden xsDown>
          <Grid item sm={7} lg={7}>
            <LazyLoad>
              <img
                src={bannerImg}
                className={classes.bannerImg}
                alt={"banner"}
              />
            </LazyLoad>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default Hero;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import Logo from "../../../assets/img/logo.png";
import Instagram from "../../../assets/img/instagram.png";
import Twitter from "../../../assets/img/twitter.png";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.dark,
    boxSizing: "border-box",
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    padding: "5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3rem",
      paddingBottom: "1rem",
      display: "flex",
      flexDirection: "column",
    },
  },
  typography: {
    color: "white",
    fontFamily: theme.typography.fontFamily,
    lineHeight: "24px",
    textDecoration: "none",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem",
      paddingLeft: "0rem",
      textAlign: "center",
    },
  },
  NavLink: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  NavSocialLink: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: "20px",
      borderTop: "1px solid white",
      borderBottom: "1px solid white",
    },
  },
  rights: {
    color: "#ffffff",
    paddingBottom: "1rem",
    paddingLeft: "5rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingLeft: 0,
    },
  },
}));

const routes = [
  { name: "About", link: "/aboutus" },
  { name: "FAQ", link: "/faq" },
  { name: "Terms of use", link: "/terms" },
  { name: "Privacy", link: "/privacy" },
  // { name: "Contact Us", link: "/" },
];

const Footer = () => {
  const classes = useStyles();
  const items = routes.map((route, index) => (
    <Typography
      key={index}
      component={Link}
      to={route.link}
      className={classes.typography}
    >
      {route.name}
    </Typography>
  ));
  return (
    <div className={classes.root}>
      <Grid
        className={classes.containerGrid}
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <img alt="logo" src={Logo} />
        </Grid>
        <Grid item className={classes.NavLink}>
          {items}
        </Grid>
        <Grid item className={classes.NavSocialLink}>
          <Grid item>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://www.instagram.com/nostalgycdotcom/";
              }}
            >
              <img alt="instagram" src={Instagram} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://twitter.com/Nostalgycdotcom";
              }}
            >
              <img alt="twitter icon" src={Twitter} />
            </IconButton>
          </Grid>
          {/* <Grid item>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://twitter.com/Nostalgycdotcom";
                }}
              >
                <img src={Twitter} />
              </IconButton>
            </Grid> */}
        </Grid>
      </Grid>
      <Typography className={classes.rights}>
        Nostalgyc © 2020 All Rights Reserved
      </Typography>
    </div>
  );
};

export default Footer;

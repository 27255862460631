import { ThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { HomeConnector } from "../modules/Home";
import { JoinConnector } from "../modules/Join";
import { StatusConnector } from "../modules/Status";
import Terms from "../modules/terms/Views/terms";
import Privacy from "../modules/PrivacyPolicy/Views/privacy";
import OnePost from "../modules/Blog/Views/oneBlog";
import AboutUs from "../modules/About/Views/aboutus";
import FAQ from "../modules/FAQ/Views/faq";
import theme from "../styles/theme";
const Routes = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/status" component={StatusConnector} />
          <Route path="/join" component={JoinConnector} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/blog/:slug" component={OnePost} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/faq" component={FAQ} />
          <Route path="" component={HomeConnector} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default Routes;

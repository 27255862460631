import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem 1.5rem 3rem 1.5rem",
    textAlign: "center",
    backgroundColor: " rgba(44, 178, 138, 1)",
    boxSizing: "border-box",
    maxWidth: "308px",
    minHeight: "321px",
    alignItems: "center",
    margin: "1rem",
    [theme.breakpoints.down("lg")]: {
      padding: "1.75rem 0 rem 2.5 rem 1.25rem",
      maxWidth: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "250px",
      padding: "2rem 1.5rem 1rem 1.5rem",
    },
  },
  ruler: {
    width: "56px",
    border: "none",
    borderTop: "1px solid",
  },
  avatar: {
    backgroundColor: " rgba(234, 187, 0, 1)",
  },
  primaryText: {
    color: "white",
    fontSize: "1.5rem",
    lineHeight: "30px",
    fontWeight: 600,
    textAlign: "left",
    marginTop: "2.5rem",
    marginBottom: "1rem",
  },
  secondaryText: {
    ...theme.typography.introText,
    fontSize: "1.5rem",
    color: "white",
    fontWeight: "600",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    // marginRight: "1.5rem",
  },

  avatarContainerGrid: {
    marginTop: "1.5rem",
    justifyContent: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
}));
const WhyJoinCard = ({ ind, pdesc, sdesc, pHeading, sHeading }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid
          className={classes.avatarContainerGrid}
          item
          container
          alignItems="center"
        >
          <Grid item>
            <Avatar className={classes.avatar}>{ind + 1}</Avatar>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.primaryText}>{pHeading}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.secondaryText}>{pdesc}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default WhyJoinCard;

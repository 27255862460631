import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, IconButton } from "@material-ui/core";
import ImageUpload from "./ImageUpload.jsx";
import { DataGrid } from "@material-ui/data-grid";
import CopyButton from "../../../assets/img/copyButton.png";
const columns = [
  { field: "id", headerName: "", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "email",
    headerName: "Email ID",
    width: 250,
  },
];

const useStyles = makeStyles((theme) => ({
  textContainer: {
    height: "100%",
    padding: "2rem 10rem",
    background: "white",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "2rem 6rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  pHeading: {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "rgba(38, 70, 83, 1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  sHeading: {
    color: "rgba(11, 18, 35, 1)",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    textAlign: "justify",
    border: "1px solid #F5F7F8",
    boxSizing: "border-box",
    borderRadius: "60px",
    marginTop: "1.25rem",
    padding: "0.75rem 5.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
      marginTop: "1rem",
      padding: "0.5rem 3.75rem",
    },
  },
  cardContainerIn: {
    margin: "0.5rem 1rem",
  },
  headingContainer: {
    marginBottom: "1rem",
  },
  formContainer: {
    // marginBottom:"2.25rem"
    // padding:"0rem 10rem"
  },
  shareText: {
    fontSize: "1rem",
    lineHeight: "1.75rem",
    fontWeight: 600,
    marginTop: "1.5rem",
    marginBottom: "1.25rem",
  },
  iconsContainer: {
    marginBottom: "1.75rem",
  },
  shareContainer: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkStatus: {
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    marginTop: "8.75rem",
  },
  tHeading: {
    fontWeight: "600",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    color: "rgba(66, 70, 136, 1)",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  },
  tableContainer: {
    textAlign: "center",
  },
}));

const StatusCheck = ({ data, query }) => {
  console.log(data);
  const classes = useStyles();
  const rows = [];
  data.joinedby.forEach((el, index) => {
    rows.push({
      id: index + 1,
      lastName: el[1],
      firstName: el[0],
      email: el[2],
    });
  });
  return (
    <Grid
      item
      container
      direction="column"
      md={8}
      alignItems="center"
      wrap="nowrap"
      className={classes.textContainer}
    >
      <Grid item className={classes.headingContainer}>
        <Typography className={classes.pHeading}>Your Status</Typography>
        <Typography className={classes.sHeading}>{data.email}</Typography>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justify="center"
        className={classes.formContainer}
      >
        <ImageUpload profile_pic={data.profile_pic} query={query} />
      </Grid>
      <Grid
        className={classes.shareContainer}
        item
        container
        alignItems="center"
        justify="center"
        wrap="nowrap"
      >
        <TextField
          id="outlined-read-only-input"
          // label="Read Only"
          defaultValue={data.ref_link}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
          fullWidth
        />
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(data.ref_link);
          }}
        >
          <img alt="copy button" style={{ padding: "0" }} src={CopyButton} />
        </IconButton>
      </Grid>
      <Grid item container alignItems="center" justify="center">
        <Typography className={classes.tHeading}>
          {`${data.joinedby.length} people joined your referral network. Refer more friends by sharing your unique referral link`}
        </Typography>
      </Grid>
      <Grid
        style={{ width: "100%", height: "400px" }}
        className={classes.tableContainer}
        item
        container
      >
        <DataGrid
          showColumnRightBorder={false}
          showCellRightBorder={false}
          autoPageSize
          autoHeight
          showToolbar={false}
          rows={rows}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

export default StatusCheck;

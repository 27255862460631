import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles({
  root: {},
  button: {
    marginTop: "1.5rem",
    background: "linear-gradient(90deg, #FF827F 5.38%, #FEE1A7 91.77%)",
    borderRadius: "40px",
    padding: "0px 48px",
    fontWeight: "600",
  },
  buttonText: {
    padding: "1rem",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  input: {
    padding: "0",
  },
  progress: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
});

const initialValues = {
  fName: "",
  email: "",
  lName: "",
  description: "",
};

const yupvalidationSchema = Yup.object().shape({
  fName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  lName: Yup.string().required("Required"),
});
const Form = ({ moveto, loading }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupvalidationSchema}
      onSubmit={(values) => {
        moveto(values);
      }}
    >
      {(formik) => {
        const {
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        } = formik;
        return (
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                id="fName"
                // label="Enter an address or landmark"
                label="Your First Name*"
                placeholder="John"
                variant="outlined"
                fullWidth
                name="fName"
                margin="normal"
                value={values.fName}
                onChange={handleChange}
                // onChange={(e) => setFirstName(e.target.value)}
                onBlur={handleBlur}
                helperText={touched.fName ? errors.fName : ""}
                error={touched.fName && Boolean(errors.fName)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="lName"
                label="Your Last Name*"
                placeholder="Doe"
                variant="outlined"
                fullWidth
                name="lName"
                margin="normal"
                value={values.lName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.lName ? errors.lName : ""}
                error={touched.lName && Boolean(errors.lName)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="email"
                label="Email*"
                placeholder="abc@example.com"
                variant="outlined"
                fullWidth
                name="email"
                margin="normal"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="desc"
                placeholder="(Optional) What is that thing which often makes you nostalgic? "
                fullWidth
                variant="filled"
                multiline
                rows={4}
                name="description"
                margin="normal"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.description ? errors.description : ""}
                error={touched.description && Boolean(errors.description)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {loading ? (
                <CircularProgress
                  style={{ width: "100px", height: "100px" }}
                  className={classes.progress}
                />
              ) : null}
              <Button
                type="submit"
                className={classes.button}
                disabled={isSubmitting}
              >
                <Typography className={classes.buttonText}>I’m in</Typography>
              </Button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};
export default Form;

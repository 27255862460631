import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem 0rem 3rem 1.5rem",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    boxSizing: "border-box",
    maxWidth: "308px",
    minHeight: "321px",
    alignItems: "center",
    margin: "1rem",
    [theme.breakpoints.down("lg")]: {
      minHeight: "300px",
      padding: "1.25rem 1 rem 2.5 rem 1.25rem",
      maxWidth: "280px",
    },
  },
  ruler: {
    width: "56px",
    border: "none",
    borderTop: "1px solid",
    color: "white",
  },
  avatar: {
    backgroundColor: "#43778C",
  },
  primaryText: {
    color: "white",
    fontSize: "1.5rem",
    lineHeight: "30px",
    fontWeight: 600,
    textAlign: "left",
    marginTop: "2.5rem",
    marginBottom: "1rem",
  },
  secondaryText: {
    color: "white",
    textAlign: "left",
    marginRight: "1.5rem",
  },
  secpText: {
    color: "white",
    fontSize: "1.5rem",
    lineHeight: "30px",
    fontWeight: 600,
    textAlign: "left",
    // marginTop:"1rem",
    marginBottom: "1rem",
  },
  secsText: {
    color: "white",
    textAlign: "left",
    marginRight: "1.5rem",
  },
  avatarContainerGrid: {
    // marginTop: "2.5rem",
  },
}));
const AmbassadorCard = ({ imgSrc, pdesc, pHeading, index }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container direction="column">
        <Grid
          className={classes.avatarContainerGrid}
          item
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <img
              alt="icon"
              style={
                index === 1
                  ? { marginTop: "-12px" }
                  : index === 3
                  ? { marginTop: "-12px" }
                  : null
              }
              src={imgSrc}
            />
          </Grid>
          <Grid item>
            <hr className={classes.ruler} />
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.primaryText}>{pHeading}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.secondaryText}>{pdesc}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default AmbassadorCard;

import * as React from "react";
import {NavBar, Footer} from "../Home";
import EarlyAccess from './View/EarlyAccess'
// import JoiningForm from "./View/joiningForm"
import {BaseURL} from "../../Utils/config"


export const JoinConnector = (props) => {
    
    const query = props.location.search
    const [refLink, setRefLink] = React.useState("")
    const [statusID, setStatID] = React.useState("")
    const [already, setAlready] = React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [invalidLink,setInvalidLink]=React.useState(false)

    const handleJoinPost = (values) => {
        console.log(values)
        setLoading(true)
        const headers = new Headers()
        headers.append("Accept", "application/json")
        var body = new FormData()
        body.append("email", values.email)
        body.append("first_name", values.fName)
        body.append("last_name", values.lName)
        body.append("desc", values.description)

        const payload = {
            method: "POST",
            headers: headers,
            body: body,
        }
        const Url = query === "" ? `${BaseURL}api/v1/join/` : `${BaseURL}api/v1/join/${query.slice(1,)}/`
        fetch(`${Url}`, payload)
        .then(response => {
            if(response.status === 400){
                setInvalidLink(true)
                setLoading(false)
            }
            if(response.status === 208){
                setAlready(true)
            }
            return response.json()
        })
        .then((response) => {
            setLoading(false)
            if(!response.status){
                setRefLink(`${response.ref_link}`)
                setStatID(response.sta_link)
            }else{
                setAlready(true)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleStatus = () => {
        props.history.push({
            pathname: '/status',
            search : `${statusID.split("?")[1]}`,
        })
    } 
    return(
        <div> 
            <NavBar/>
                <EarlyAccess
                    temp={1} 
                    refLink={refLink}
                    already={already}
                    moveto={handleJoinPost}
                    statusfunc={handleStatus}
                    query={query}
                    loading={loading}
                    invalidLink={invalidLink}
                    />
                {/* <JoiningForm 
                    setEmail={setEmail} 
                    setFirstName={setFirstName} 
                    setLastName={setLastName} 
                    refLink={refLink}
                    moveto={handleJoinPost}
                /> */}
            <Footer />
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { NavBar, Footer } from "../../Home";
import sanityClient from "../../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function AboutUs(props) {
  const [aboutusData, setaboutusData] = useState(null);
  const slug = "aboutus";

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "aboutus"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setaboutusData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!aboutusData) return <div>Loading...</div>;

  return (
    <div>
      <NavBar />
      <div className="bg-gray-200 min-h-screen p-12">
        <div className="container shadow-lg mx-auto bg-green-100 rounded-lg">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center p-8">
              {/* Title Section */}
              {/* <div className="bg-white bg-opacity-75 rounded p-12"> */}
              <h2 className="text-3xl lg:text-6xl mb-4">{aboutusData.title}</h2>
              {/* </div> */}
            </div>
            <img
              className="w-full object-cover rounded-t"
              src={urlFor(aboutusData.mainImage).url()}
              alt=""
              style={{ height: "200px" }}
            />
          </div>
          <div className="px-16 lg:px-48 py-12 lg:py-20 prose lg:prose-xl max-w-full">
            <BlockContent
              blocks={aboutusData.body}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

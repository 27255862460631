import { orange } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const font="'Poppins', sans-serif"

const theme = createMuiTheme({
  palette: {
    tertiary:{
      main:"#E5E5E5"
    },
    secondary: {
      main: orange[500],
    },
    primary : {
      main : "#264653",
      light: "#F1F4F1",
      dark: "#0B1223",
    }
  },

  typography: {
    introText:{
      fontFamily:font
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

});

export default theme;
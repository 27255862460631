import * as React from "react";
import Hero from "./View/heroView";
import NavBar from "./View/navBarView";
import Footer from "./View/footer";
import WhyNostalgic from "./View/whyJoinNostalgicView";
import HowNostalgicWorks from "./View/howNostalgicWorksView";
import BecomeAmbassador from "./View/ambasssadorView";
import AllPosts from "./View/blogView";

export const HomeConnector = (props) => {
  const query = props.location.search;

  const handleJoin = () => {
    console.log("hi there");
    props.history.push({
      pathname: "/join",
      search: `${query}`,
    });
  };
  return (
    <div>
      <NavBar MoveToProfile={handleJoin} />
      <Hero moveto={handleJoin} />
      <WhyNostalgic />
      <HowNostalgicWorks />
      {/* <AllPosts /> */}
      <Footer />
    </div>
  );
};

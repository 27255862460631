import * as React from "react";
import { NavBar, Footer } from "../../Home";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  privacyUp: {
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      textAlign: "justify",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
}));
const Terms = (props) => {
  const classes = useStyles();
  return (
    <div>
      <NavBar />
      <div className={classes.privacyUp}>
        <div className="privacy">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center text-center">
                <Typography variant="h4">TERMS OF SERVICE</Typography>
              </div>
              <div className="col-12 d-flex justify-content-center text-center">
                <Typography variant="h6">Updated: 28th Jan 2022</Typography>
              </div>
              <Typography>
                <strong>WELCOME TO NOSTALGYC</strong>
              </Typography>

              <Typography>
                Thank you for using the Nostalgyc platform and the products,
                services and features we make available to you as part of the
                platform (collectively, the “Service”). The Service allows you
                to request, discover, watch and share videos and other content,
                and acts as a distribution platform for original content
                creators and advertisers. This Terms of Service Agreement
                (“Agreement”) constitutes a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (“user” or “you”) and Nostalgyc LLC and its affiliated companies
                (collectively, “Company” or “we” or “us” or “our”), concerning
                your access to and use of the https://www.nostalgyc.com website
                as well as any other media form, media channel, mobile website
                or mobile application related or connected thereto
                (collectively, the “Website”).{" "}
              </Typography>

              <Typography>
                Company makes no representation that the Website is appropriate
                or available in other locations other than where it is operated
                by Company. The information provided on the Website is not
                intended for distribution to or use by any person or entity in
                any jurisdiction or country where such distribution or use would
                be contrary to law or regulation or which would subject Company
                to any registration requirement within such jurisdiction or
                country. Accordingly, those persons who choose to access the
                Website from other locations do so on their own initiative and
                are solely responsible for compliance with local laws, if and to
                the extent local laws are applicable.{" "}
              </Typography>

              <Typography>
                YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY
                ACKNOWLEDGING SUCH ACCEPTANCE DURING THE REGISTRATION PROCESS
                (IF APPLICABLE) AND ALSO BY CONTINUING TO USE THE WEBSITE. IF
                YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS
                THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT
                USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE COMPANY SERVICES
                OR THE WEBSITE.{" "}
              </Typography>

              <Typography>
                <strong>CONTENT ON THE SERVICE</strong>
              </Typography>

              <Typography>
                The content on the Service includes videos, audio (for example
                music and other sounds), graphics, photos, text (such as
                requests, topics, comments and scripts), location details of
                videos, branding (including trade names, trademarks, service
                marks, or logos), interactive features, software, metrics, and
                other materials whether provided or uploaded by you, Nostalgyc
                or a third-party (collectively, "Content”).
              </Typography>

              <Typography>
                Content is the responsibility of the person or entity that
                provides it to the Service. Nostalgyc is under no obligation to
                host or serve Content. If you see any Content you believe does
                not comply with this Agreement, including by violating the law,
                you can report it to us by emailing at support@nostalgyc.com.
                Our Privacy Policy explains how we treat your personal data and
                protect your privacy when you use the Service.{" "}
              </Typography>

              <Typography>
                <strong>USER REPRESENTATIONS: </strong>{" "}
              </Typography>

              <Typography>
                Regarding Your Registration - By using the Company Services, you
                represent and warrant that:
              </Typography>

              <Typography>
                A. all registration information you submit is truthful and
                accurate;
              </Typography>

              <Typography>
                B. you will maintain the accuracy of such information;
              </Typography>

              <Typography>
                C. you will keep your password confidential and will be
                responsible for all use of your password and account;
              </Typography>

              <Typography>
                D. you are not a minor in the jurisdiction in which you reside,
                or if a minor, you have received parental permission to use this
                Website; and
              </Typography>

              <Typography>
                E. your use of the Company Services does not violate any
                applicable law or regulation.
              </Typography>

              <Typography>
                You also agree to: (a) provide true, accurate, current and
                complete information about yourself as prompted by the Website’s
                registration form and (b) maintain and promptly update
                registration data to keep it true, accurate, current and
                complete. If you provide any information that is untrue,
                inaccurate, not current or incomplete, or Company has reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, Company has the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Website (or any portion thereof).{" "}
              </Typography>

              <Typography>
                We reserve the right to remove or reclaim or change a user name
                you select if we determine appropriate in our discretion, such
                as when the user name is obscene or otherwise objectionable or
                when a trademark owner complains about a username that does not
                closely relate to a user's actual name.{" "}
              </Typography>

              <Typography>
                <strong>
                  <em>Regarding Content You Provide</em>
                </strong>
              </Typography>

              <Typography>
                The Website may invite you to upload profile picture, videos,
                provide location details of videos, chat, participate in blogs,
                message boards, online forums and other functionality and may
                provide you with the opportunity to create, submit, post,
                display, transmit, perform, publish, distribute or broadcast
                content and materials to Company and/or to or via the Website,
                including, without limitation, text, writings, video, audio,
                photographs, graphics, locations, comments, suggestions or
                personally identifiable information or other material
                (collectively "Contributions"). Any Contributions you transmit
                to Company will be treated as non-confidential and
                non-proprietary. When you create or make available a
                Contribution, you thereby represent and warrant that:
              </Typography>

              <Typography>
                A. the creation, distribution, transmission, public display and
                performance, accessing, downloading and copying of your
                Contribution does not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret or moral rights of any third party;
              </Typography>

              <Typography>
                B. you are the creator and owner of or have the necessary
                licenses, rights, consents, releases and permissions to use and
                to authorize Company and the Website users to use your
                Contributions as necessary to exercise the licenses granted by
                you under this Agreement;
              </Typography>

              <Typography>
                C. you have the written consent, release, and/or permission of
                each and every identifiable individual person in the
                Contribution to use the name or likeness of each and every such
                identifiable individual person to enable inclusion and use of
                the Contribution in the manner contemplated by this Website;
              </Typography>

              <Typography>
                D. your Contribution is not obscene, lewd, lascivious, filthy,
                violent, harassing or otherwise objectionable (as determined by
                Company), libelous or slanderous, does not ridicule, mock,
                disparage, intimidate or abuse anyone, does not advocate the
                violent overthrow of any government, does not incite, encourage
                or threaten physical harm against another, does not violate any
                applicable law, regulation, or rule, and does not violate the
                privacy or publicity rights of any third party;
              </Typography>

              <Typography>
                E. your Contribution does not contain material that solicits
                personal information from anyone under 13 or exploit people
                under the age of 18 in a sexual or violent manner, and does not
                violate any federal or state law concerning child pornography or
                otherwise intended to protect the health or well-being of
                minors;
              </Typography>

              <Typography>
                F. your Contribution does not include any offensive comments
                that are connected to race, national origin, gender, sexual
                preference or physical handicap;
              </Typography>

              <Typography>
                G. your Contribution does not otherwise violate, or link to
                material that violates, any provision of this Agreement or any
                applicable law or regulation.
              </Typography>

              <Typography>
                <strong>CONTRIBUTION LICENSE</strong>
              </Typography>

              <Typography>
                By posting Contributions to any part of the Website, or making
                them accessible to the Website by linking your account to any of
                your social network accounts, you automatically grant, and you
                represent and warrant that you have the right to grant, to
                Company an unrestricted, unconditional, unlimited, irrevocable,
                perpetual, non-exclusive, transferable, royalty-free,
                fully-paid, worldwide right and license to host, use, copy,
                reproduce, disclose, sell, resell, publish, broadcast, retitle,
                archive, store, cache, publicly perform, publicly display,
                reformat, translate, transmit, excerpt (in whole or in part) and
                distribute such Contributions (including, without limitation,
                your image and voice) for any purpose, commercial, advertising,
                or otherwise, to prepare derivative works of, or incorporate
                into other works, such Contributions, and to grant and authorize
                sublicenses of the foregoing. The use and distribution may occur
                in any media formats and through any media channels. Such use
                and distribution license will apply to any form, media, or
                technology now known or hereafter developed, and includes our
                use of your name, company name, and franchise name, as
                applicable, and any of the trademarks, service marks, trade
                names and logos, personal and commercial images you provide.
                Company does not assert any ownership over your Contributions;
                rather, as between us and you, subject to the rights granted to
                us in this Agreement, you retain full ownership of all of your
                Contributions and any intellectual property rights or other
                proprietary rights associated with your Contributions.{" "}
              </Typography>

              <Typography>
                Company has the right, in our sole and absolute discretion, to
                (i) edit, redact or otherwise change any Contributions, (ii)
                re-categorize any Contributions to place them in more
                appropriate locations or (iii) pre-screen or delete any
                Contributions that are determined to be inappropriate or
                otherwise in violation of this Agreement.
              </Typography>

              <Typography>
                By uploading your Contributions to the Website, you hereby
                authorize Company to grant to each end user a personal, limited,
                no-transferable, perpetual, non-exclusive, royalty-free,
                fully-paid license to access, download, print and otherwise use
                your Contributions for their internal purposes and not for
                distribution, transfer, sale or commercial exploitation of any
                kind.{" "}
              </Typography>

              <Typography>
                <strong>
                  GUIDELINES FOR REVIEWS and COMMENTS
                  <br />{" "}
                </strong>
              </Typography>

              <Typography>
                Company may accept, reject or remove reviews and comments in its
                sole discretion. Company has absolutely no obligation to screen
                reviews or to delete reviews, even if anyone considers reviews
                objectionable or inaccurate. Those persons posting reviews
                should comply with the following criteria: (1) reviewers should
                have firsthand experience with the person/entity being reviewed;
                (2) reviews should not contain: offensive language, profanity,
                or abusive, racist, or hate language; discriminatory references
                based on religion, race, gender, national origin, age, marital
                status, sexual orientation or disability; or references to
                illegal activity; (3) reviewers should not be affiliated with
                competitors if posting negative reviews; (4) reviewers should
                not make any conclusions as to the legality of conduct; and (5)
                reviewers may not post any false statements or organize a
                campaign encouraging others to post reviews, whether positive or
                negative. Reviews are not endorsed by Company, and do not
                represent the views of Company or of any affiliate or partner of
                Company. Company does not assume liability for any review or for
                any claims, liabilities or losses resulting from any review. By
                posting a review, the reviewer hereby grants to Company a
                perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
                assignable and sublicensable license to Company to reproduce,
                modify, translate, transmit by any means, display, perform
                and/or distribute all content relating to reviews.{" "}
              </Typography>

              <h2>SOCIAL MEDIA </h2>

              <Typography>
                As part of the functionality of the Website, you may link your
                account with online accounts you may have with third party
                service providers (each such account, a “Third Party Account”)
                by either: (i) providing your Third Party Account login
                information through the Website; or (ii) allowing Company to
                access your Third Party Account, as is permitted under the
                applicable terms and conditions that govern your use of each
                Third Party Account. You represent that you are entitled to
                disclose your Third Party Account login information to Company
                and/or grant Company access to your Third Party Account
                (including, but not limited to, for use for the purposes
                described herein), without breach by you of any of the terms and
                conditions that govern your use of the applicable Third Party
                Account and without obligating Company to pay any fees or making
                Company subject to any usage limitations imposed by such third
                party service providers. By granting Company access to any Third
                Party Accounts, you understand that (i) Company may access, make
                available and store (if applicable) any content that you have
                provided to and stored in your Third Party Account (the “Social
                Network Content”) so that it is available on and through the
                Website via your account, including without limitation any
                friend lists, and (ii) Company may submit and receive additional
                information to your Third Party Account to the extent you are
                notified when you link your account with the Third Party
                Account. Depending on the Third Party Accounts you choose and
                subject to the privacy settings that you have set in such Third
                Party Accounts, personally identifiable information that you
                post to your Third Party Accounts may be available on and
                through your account on the Website. Please note that if a Third
                Party Account or associated service becomes unavailable or
                Company’s access to such Third Party Account is terminated by
                the third party service provider, then Social Network Content
                may no longer be available on and through the Website. You will
                have the ability to disable the connection between your account
                on the Website and your Third Party Accounts at any time. PLEASE
                NOTE THAT YOUR RELATIONSHIP WITH THE THIRD PARTY SERVICE
                PROVIDERS ASSOCIATED WITH YOUR THIRD PARTY ACCOUNTS IS GOVERNED
                SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD PARTY SERVICE
                PROVIDERS. Company makes no effort to review any Social Network
                Content for any purpose, including but not limited to, for
                accuracy, legality or non-infringement, and Company is not
                responsible for any Social Network Content. You acknowledge and
                agree that Company may access your e-mail address book
                associated with a Third Party Account and your contacts list
                stored on your mobile device or tablet computer solely for the
                purposes of identifying and informing you of those contacts who
                have also registered to use the Website. At your request made
                via email to our email address listed below, or through your
                account settings (if applicable), Company will deactivate the
                connection between the Website and your Third Party Account and
                delete any information stored on Company’s servers that was
                obtained through such Third Party Account, except the username
                and profile picture that become associated with your account.{" "}
              </Typography>

              <h2>SUBMISSIONS</h2>

              <Typography>
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback or other information about the
                Website or the Company Services ("Submissions") provided by you
                to Company are non-confidential and Company (as well as any
                designee of Company) shall be entitled to the unrestricted use
                and dissemination of these Submissions for any purpose,
                commercial or otherwise, without acknowledgment or compensation
                to you.
              </Typography>

              <h2>YOUR CONTENT AND CONDUCT</h2>

              <Typography>
                Uploading Content: If you choose to upload your Content, you
                must not upload to the Service any Content that does not comply
                with this Agreement or the law. For example, the Content you
                upload must not include third-party intellectual property (such
                as copyrighted material) unless you have permission from that
                party or are otherwise legally entitled to do so. You are
                legally responsible for the Content you submit to the Service.
                We may use a manual review or an automated system that analyzes
                your Content to help detect infringement and abuse, such as
                spam, malware, and illegal content. Nostalgyc will have the sole
                right to determine what Content gets uploaded to the Service and
                for what duration.{" "}
              </Typography>

              <Typography>
                <strong>Rights you Grant</strong>
              </Typography>

              <Typography>
                You retain ownership rights to your Content. However, we do
                require you to grant certain rights to Nostalgyc and other users
                of the Service, as described below.
              </Typography>

              <Typography>
                <strong>License to Nostalgyc</strong>
              </Typography>

              <Typography>
                By providing Content to the Service and by uploading profile
                pictures, videos or any media, you grant to Nostalgyc a
                worldwide, non-exclusive, royalty-free, sublicensable and
                transferable license to use that Content (including to
                reproduce, distribute, prepare derivative works, display and
                perform it) in connection with the Service and Nostalgyc’s (and
                its successors' and Affiliates') business, including for the
                purpose of promoting and redistributing part or all of the
                Service. You grant to Nostalgyc a worldwide, non-exclusive,
                royalty-free, sublicensable and transferable license to use you
                profile picture to develop a picture collage with other users on
                the platform and distribute the picture collage to you and other
                users on the platform.
              </Typography>

              <Typography>
                <strong>License to Other Users</strong>
              </Typography>

              <Typography>
                You also grant each other user of the Service a worldwide,
                non-exclusive, royalty-free license to access your Content
                through the Service, and to use that Content, including to
                reproduce, distribute, prepare derivative works, display, and
                perform it, only as enabled by a feature of the Service (such as
                video playback, embeds and social sharing). For clarity, this
                license does not grant any rights or permissions for a user to
                make use of your Content independent of the Service. You grant
                your friends ("followers", "people you follow" and people who
                joined the platform using your referral link) and users of the
                Service to use your profile picture in a photo collage for
                personal non-commercial use.
              </Typography>

              <Typography>
                <strong>Duration of License</strong>
              </Typography>

              <Typography>
                The licenses granted by you continue for a commercially
                reasonable period of time after you remove or delete your
                Content from the Service. You understand and agree, however,
                that Nostalgyc may retain, but not display, distribute, or
                perform, server copies of your videos that have been removed or
                deleted.{" "}
              </Typography>

              <Typography>
                <strong>Removing Your Content</strong>
              </Typography>

              <Typography>
                You may use the delete button provided on the website or request
                the removal of your Content from the Service at any time by
                emailing support@nostalgyc.com. You must request removal of your
                Content if you no longer have the rights required by these
                terms. Even though we may delete a Content from the Website or
                the Content is not accessible by website users, a copy of the
                Content could still be present in our database server or cloud
                storage services provided by third parties.{" "}
              </Typography>

              <Typography>
                <strong>Removal of Content By Nostalgyc</strong>
              </Typography>

              <Typography>
                If we reasonably believe that any Content is in breach of this
                Agreement or may cause harm to Nostalgyc, our users, or third
                parties, we may remove or take down that Content in our
                discretion.{" "}
              </Typography>

              <Typography>
                <strong>Copyright Protection</strong>
              </Typography>

              <Typography>
                If you believe your copyright has been infringed on the Service,
                please email us at support@nostalgyc.com
              </Typography>

              <Typography>
                <strong>PROHIBITED ACTIVITIES</strong>
              </Typography>

              <Typography>
                You may access and use the Service as made available to you, as
                long as you comply with this Agreement and applicable law. You
                may view or listen to Content for your personal, non-commercial
                use. You may not access or use the Website for any other purpose
                other than that for which Company makes it available. The
                Website may not be used in connection with any commercial
                endeavors except those that are specifically endorsed or
                approved by Company. Prohibited activity includes, but is not
                limited to:{" "}
              </Typography>

              <Typography>
                A. attempting to bypass any measures of the Website designed to
                prevent or restrict access to the Website, or any portion of the
                Website{" "}
              </Typography>

              <Typography>
                B. attempting to impersonate another user or person or using the
                username of another user{" "}
              </Typography>

              <Typography>C. criminal or tortious activity </Typography>

              <Typography>
                D. deciphering, decompiling, disassembling or reverse
                engineering any of the software comprising or in any way making
                up a part of the Website{" "}
              </Typography>

              <Typography>
                E. deleting the copyright or other proprietary rights notice
                from any Website content{" "}
              </Typography>

              <Typography>
                F. engaging in any automated use of the system, such as using
                any data mining, robots or similar data gathering and extraction
                tools{" "}
              </Typography>

              <Typography>
                G. except as may be the result of standard search engine or
                Internet browser usage, using or launching, developing or
                distributing any automated system, including, without
                limitation, any spider, robot (or "bot"), cheat utility, scraper
                or offline reader that accesses the Website, or using or
                launching any unauthorized script or other software{" "}
              </Typography>

              <Typography>
                H. harassing, annoying, intimidating or threatening any Company
                employees or agents engaged in providing any portion of the
                Company Services to you{" "}
              </Typography>

              <Typography>
                I. interfering with, disrupting, or creating an undue burden on
                the Website or the networks or services connected to the Website{" "}
              </Typography>

              <Typography>
                J. making any unauthorized use of the Company Services,
                including collecting usernames and/or email addresses of users
                by electronic or other means for the purpose of sending
                unsolicited email, or creating user accounts by automated means
                or under false pretenses{" "}
              </Typography>

              <Typography>
                K. selling or otherwise transferring your profile{" "}
              </Typography>

              <Typography>
                L. systematic retrieval of data or other content from the
                Website to create or compile, directly or indirectly, a
                collection, compilation, database or directory without written
                permission from Company{" "}
              </Typography>

              <Typography>
                M. tricking, defrauding or misleading Company and other users,
                especially in any attempt to learn sensitive account information
                such as passwords{" "}
              </Typography>

              <Typography>
                N. using any information obtained from the Website in order to
                harass, abuse, or harm another person{" "}
              </Typography>

              <Typography>
                O. using the Company Services as part of any effort to compete
                with Company or to provide services as a service bureau{" "}
              </Typography>

              <Typography>
                P. using the Website in a manner inconsistent with any and all
                applicable laws and regulations{" "}
              </Typography>

              <Typography>
                Q. circumvent, disable, fraudulently engage with, or otherwise
                interfere with any part of the Service (or attempt to do any of
                these things), including security-related features or features
                that (a) prevent or restrict the copying or other use of Content
                or (b) limit the use of the Service or Content;
              </Typography>

              <Typography>
                R. access the Service using any automated means (such as robots,
                botnets or scrapers) except (a) in the case of public search
                engines, in accordance with Nostalgyc’s robots.txt file; or (b)
                with Nostalgyc’s prior written permission;{" "}
              </Typography>

              <Typography>
                S. collect or harvest any information that might identify a
                person(s){" "}
              </Typography>

              <Typography>
                T. use the Service to distribute unsolicited promotional or
                commercial content or other unwanted or mass solicitations;
              </Typography>

              <Typography>
                U. cause or encourage any inaccurate measurements of genuine
                user engagement with the Service, or otherwise manipulate
                metrics in any manner;
              </Typography>

              <Typography>
                W. misuse any reporting, flagging, complaint, dispute, or
                appeals process, including by making groundless, vexatious, or
                frivolous submissions;
              </Typography>

              <Typography>
                X. use the Service to view or listen to Content other than for
                personal, non-commercial use (for example, you may not publicly
                screen videos or stream music from the Service); or
              </Typography>

              <Typography>
                Y. use the Service to sell any advertising, sponsorships, or
                promotions placed on, around, or within the Service or Content
              </Typography>

              <Typography>
                <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
              </Typography>

              <Typography>
                The content on the Website (“Company Content”) and the
                trademarks, service marks and logos contained therein (“Marks”)
                are owned by or licensed to Company, and are subject to
                copyright and other intellectual property rights under United
                States and foreign laws and international conventions. Company
                Content, includes, without limitation, all source code,
                databases, functionality, software, website designs, audio,
                video, text, photographs and graphics. All Company graphics,
                logos, designs, page headers, button icons, scripts and service
                names are registered trademarks, common law trademarks or trade
                dress of Company in the United States and/or other countries.
                Company's trademarks and trade dress may not be used, including
                as part of trademarks and/or as part of domain names, in
                connection with any product or service in any manner that is
                likely to cause confusion and may not be copied, imitated, or
                used, in whole or in part, without the prior written permission
                of the Company.{" "}
              </Typography>

              <Typography>
                Company Content on the Website is provided to you “AS IS” for
                your information and personal use only and may not be used,
                copied, reproduced, aggregated, distributed, transmitted,
                broadcast, displayed, sold, licensed, or otherwise exploited for
                any other purposes whatsoever without the prior written consent
                of the respective owners. Provided that you are eligible to use
                the Website, you are granted a limited license to access and use
                the Website and the Company Content and to download or print a
                copy of any portion of the Company Content to which you have
                properly gained access solely for your personal, non-commercial
                use. Company reserves all rights not expressly granted to you in
                and to the Website and Company Content and Marks.{" "}
              </Typography>

              <h2>THIRD PARTY WEBSITES AND CONTENT</h2>

              <Typography>
                The Website contains (or you may be sent through the Website or
                the Company Services) links to other websites ("Third Party
                Websites") as well as articles, photographs, text, graphics,
                pictures, designs, music, sound, video, information,
                applications, software and other content or items belonging to
                or originating from third parties (the "Third Party Content").
                Such Third Party Websites and Third Party Content are not
                investigated, monitored or checked for accuracy,
                appropriateness, or completeness by us, and we are not
                responsible for any Third Party Websites accessed through the
                Website or any Third Party Content posted on, available through
                or installed from the Website, including the content, accuracy,
                offensiveness, opinions, reliability, privacy practices or other
                policies of or contained in the Third Party Websites or the
                Third Party Content. Inclusion of, linking to or permitting the
                use or installation of any Third Party Website or any Third
                Party Content does not imply approval or endorsement thereof by
                us. If you decide to leave the Website and access the Third
                Party Websites or to use or install any Third Party Content, you
                do so at your own risk and you should be aware that our terms
                and policies no longer govern. You should review the applicable
                terms and policies, including privacy and data gathering
                practices, of any website to which you navigate from the Website
                or relating to any applications you use or install from the
                Website. Any purchases you make through Third Party Websites
                will be through other websites and from other companies, and
                Company takes no responsibility whatsoever in relation to such
                purchases which are exclusively between you and the applicable
                third party.
              </Typography>

              <Typography>
                <strong>SITE MANAGEMENT</strong>
              </Typography>

              <Typography>
                Company reserves the right but does not have the obligation to:
              </Typography>

              <Typography>
                A. monitor the Website for violations of this Agreement;
              </Typography>

              <Typography>
                B. take appropriate legal action against anyone who, in
                Company’s sole discretion, violates this Agreement, including
                without limitation, reporting such user to law enforcement
                authorities;
              </Typography>

              <Typography>
                C. in Company’s sole discretion and without limitation, refuse,
                restrict access to or availability of, or disable (to the extent
                technologically feasible) any user’s contribution or any portion
                thereof that may violate this Agreement or any Company policy;
              </Typography>

              <Typography>
                D. in Company’s sole discretion and without limitation, notice
                or liability to remove from the Website or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to Company’s systems;
              </Typography>

              <Typography>
                E. otherwise manage the Website in a manner designed to protect
                the rights and property of Company and others and to facilitate
                the proper functioning of the Website.{" "}
              </Typography>

              <Typography>
                <strong>TERM AND TERMINATION</strong>
              </Typography>

              <Typography>
                This Agreement shall remain in full force and effect while you
                use the Website or are otherwise a user or member of the
                Website, as applicable. You may terminate your use or
                participation at any time, for any reason, by following the
                instructions for terminating user accounts in your account
                settings, if available, or by contacting us using the contact
                information below.{" "}
              </Typography>

              <Typography>
                WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, COMPANY
                RESERVES THE RIGHT TO, IN COMPANY’S SOLE DISCRETION AND WITHOUT
                NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE AND
                THE COMPANY SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO
                REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THIS
                AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND COMPANY
                MAY TERMINATE YOUR USE OR PARTICIPATION IN THE WEBSITE AND THE
                COMPANY SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR
                INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING,
                IN COMPANY’S SOLE DISCRETION.{" "}
              </Typography>

              <Typography>
                In order to protect the integrity of the Website and Company
                Services, Company reserves the right at any time in its sole
                discretion to block certain IP addresses from accessing the
                Website and Company Services.
              </Typography>

              <Typography>
                Any provisions of this Agreement that, in order to fulfill the
                purposes of such provisions, need to survive the termination or
                expiration of this Agreement, shall be deemed to survive for as
                long as necessary to fulfill such purposes.
              </Typography>

              <Typography>
                YOU UNDERSTAND THAT CERTAIN STATES ALLOW YOU TO CANCEL THIS
                AGREEMENT, WITHOUT ANY PENALTY OR OBLIGATION, AT ANY TIME PRIOR
                TO MIDNIGHT OF COMPANY’S THIRD BUSINESS DAY FOLLOWING THE DATE
                OF THIS AGREEMENT, EXCLUDING SUNDAYS AND HOLIDAYS. TO CANCEL,
                CALL A COMPANY CUSTOMER CARE REPRESENTATIVE DURING NORMAL
                BUSINESS HOURS USING THE CONTACT INFORMATION LISTING BELOW IN
                THIS AGREEMENT OR BY ACCESSING YOUR ACCOUNT SETTINGS. THIS
                SECTION APPLIES ONLY TO INDIVIDUALS RESIDING IN STATES WITH SUCH
                LAWS.
              </Typography>

              <Typography>
                If Company terminates or suspends your account for any reason,
                you are prohibited from registering and creating a new account
                under your name, a fake or borrowed name, or the name of any
                third party, even if you may be acting on behalf of the third
                party. In addition to terminating or suspending your account,
                Company reserves the right to take appropriate legal action,
                including without limitation pursuing civil, criminal, and
                injunctive redress.
              </Typography>

              <Typography>
                <strong>MODIFICATIONS</strong>
              </Typography>

              <Typography>
                <strong>
                  <em>To Agreement</em>
                </strong>
              </Typography>

              <Typography>
                Company may modify this Agreement from time to time. Any and all
                changes to this Agreement will be posted on the Website and
                revisions will be indicated by date. You agree to be bound to
                any changes to this Agreement when you use the Company Services
                after any such modification becomes effective. Company may also,
                in its discretion, choose to alert all users with whom it
                maintains email information of such modifications by means of an
                email to their most recently provided email address. It is
                therefore important that you regularly review this Agreement and
                keep your contact information current in your account settings
                to ensure you are informed of changes. You agree that you will
                periodically check the Website for updates to this Agreement and
                you will read the messages we send you to inform you of any
                changes. Modifications to this Agreement shall be effective
                after posting.{" "}
              </Typography>

              <Typography>
                <strong>
                  <em>To Services</em>
                </strong>
              </Typography>

              <Typography>
                Company reserves the right at any time to modify or discontinue,
                temporarily or permanently, the Company Services (or any part
                thereof) with or without notice. You agree that Company shall
                not be liable to you or to any third party for any modification,
                suspension or discontinuance of the Company Services.{" "}
              </Typography>

              <Typography>
                <strong>DISPUTES</strong>
              </Typography>

              <Typography>
                <strong>
                  <em>Between Users</em>
                </strong>
              </Typography>

              <Typography>
                If there is a dispute between users of the Website, or between
                users and any third party, you understand and agree that Company
                is under no obligation to become involved. In the event that you
                have a dispute with one or more other users, you hereby release
                Company, its officers, employees, agents and successors in
                rights from claims, demands and damages (actual and
                consequential) of every kind or nature, known or unknown,
                suspected and unsuspected, disclosed and undisclosed, arising
                out of or in any way related to such disputes and/or the Company
                Services.{" "}
              </Typography>

              <Typography>
                <strong>
                  <em>With Company</em>
                </strong>
              </Typography>

              <Typography>
                <strong>A. Governing Law; Jurisdiction. </strong>This Agreement
                and all aspects of the Website and Company Services shall be
                governed by and construed in accordance with the internal laws
                of the State of Illinois, without regard to conflict of law
                provisions. With respect to any disputes or claims not subject
                to informal dispute resolution or arbitration (as set forth
                below), you agree not to commence or prosecute any action in
                connection therewith other than in the state and federal courts
                located in State of Illinois, USA, and you hereby consent to,
                and waive all defenses of lack of personal jurisdiction and
                forum non conveniens with respect to, venue and jurisdiction in
                such state and federal courts. Application of the United Nations
                Convention on Contracts for the International Sale of Goods is
                excluded from this Agreement. Additionally, application of the
                Uniform Computer Information Transaction Act (UCITA) is excluded
                from this Agreement. In no event shall any claim, action or
                proceeding by you related in any way to the Website or Company
                Services be instituted more than two (2) years after the cause
                of action arose.
              </Typography>

              <Typography>
                <strong>B. Informal Resolution. </strong>To expedite resolution
                and control the cost of any dispute, controversy or claim
                related to this Agreement ("Dispute"), you and Company agree to
                first attempt to negotiate any Dispute (except those Disputes
                expressly provided below) informally for at least thirty (30)
                days before initiating any arbitration or court proceeding. Such
                informal negotiations commence upon written notice from one
                person to the other.
              </Typography>

              <Typography>
                <strong>C. Binding Arbitration.</strong> If you and Company are
                unable to resolve a Dispute through informal negotiations,
                either you or Company may elect to have the Dispute (except
                those Disputes expressly excluded below) finally and exclusively
                resolved by binding arbitration. Any election to arbitrate by
                one party shall be final and binding on the other. YOU
                UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT
                TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be
                commenced and conducted under the Commercial Arbitration Rules
                of the American Arbitration Association ("AAA") and, where
                appropriate, the AAA’s Supplementary Procedures for Consumer
                Related Disputes ("AAA Consumer Rules"), both of which are
                available at the AAA website www.adr.org. The determination of
                whether a Dispute is subject to arbitration shall be governed by
                the Federal Arbitration Act and determined by a court rather
                than an arbitrator. Your arbitration fees and your share of
                arbitrator compensation shall be governed by the AAA Consumer
                Rules and, where appropriate, limited by the AAA Consumer Rules.
                If such costs are determined by the arbitrator to be excessive,
                Company will pay all arbitration fees and expenses. The
                arbitration may be conducted in person, through the submission
                of documents, by phone or online. The arbitrator will make a
                decision in writing, but need not provide a statement of reasons
                unless requested by a party. The arbitrator must follow
                applicable law, and any award may be challenged if the
                arbitrator fails to do so. Except where otherwise required by
                the applicable AAA rules or applicable law, the arbitration will
                take place in State of Illinois, USA. Except as otherwise
                provided in this Agreement, you and Company may litigate in
                court to compel arbitration, stay proceedings pending
                arbitration, or to confirm, modify, vacate or enter judgment on
                the award entered by the arbitrator.
              </Typography>

              <Typography>
                <strong>D. Restrictions. </strong>You and Company agree that any
                arbitration shall be limited to the Dispute between Company and
                you individually. To the full extent permitted by law, (1) no
                arbitration shall be joined with any other; (2) there is no
                right or authority for any Dispute to be arbitrated on a
                class-action basis or to utilize class action procedures; and
                (3) there is no right or authority for any Dispute to be brought
                in a purported representative capacity on behalf of the general
                public or any other persons.
              </Typography>

              <Typography>
                <strong>
                  E. Exceptions to Informal Negotiations and Arbitration.
                </strong>{" "}
                You and Company agree that the following Disputes are not
                subject to the above provisions concerning informal negotiations
                and binding arbitration: (1) any Disputes seeking to enforce or
                protect, or concerning the validity of any of your or Company’s
                intellectual property rights; (2) any Dispute related to, or
                arising from, allegations of theft, piracy, invasion of privacy
                or unauthorized use; and (3) any claim for injunctive relief. If
                this Section is found to be illegal or unenforceable then
                neither you nor Company will elect to arbitrate any Dispute
                falling within that portion of this Section found to be illegal
                or unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and you and Company agree to submit to the personal
                jurisdiction of that court.{" "}
              </Typography>

              <Typography>
                <strong>CORRECTIONS</strong>
              </Typography>

              <Typography>
                Occasionally there may be information on the Website that
                contains typographical errors, inaccuracies or omissions that
                may relate to service descriptions, pricing, availability, and
                various other information. Company reserves the right to correct
                any errors, inaccuracies or omissions and to change or update
                the information at any time, without prior notice.{" "}
              </Typography>

              <Typography>
                <strong>DISCLAIMERS</strong>
              </Typography>

              <Typography>
                Company cannot control the nature of all of the content
                available on the Website. By operating the Website, Company does
                not represent or imply that Company endorses any blogs,
                contributions or other content available on or linked to by the
                Website, including without limitation content hosted on third
                party websites or provided by third party applications, or that
                Company believes contributions, blogs or other content to be
                accurate, useful or non-harmful. We do not control and are not
                responsible for unlawful or otherwise objectionable content you
                may encounter on the Website or in connection with any
                contributions. The Company is not responsible for the conduct,
                whether online or offline, of any user of the Website or Company
                Services.
              </Typography>

              <Typography>
                YOU AGREE THAT YOUR USE OF THE WEBSITE AND COMPANY SERVICES WILL
                BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
                COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM
                ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                WEBSITE AND THE COMPANY SERVICES AND YOUR USE THEREOF,
                INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT. COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS
                ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE’S CONTENT OR
                THE CONTENT OF ANY WEBSITES LINKED TO THIS WEBSITE AND ASSUMES
                NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR
                INACCURACIES OF CONTENT AND MATERIALS, (B) PERSONAL INJURY OR
                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                ACCESS TO AND USE OF OUR WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO
                OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY
                INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE
                OR COMPANY SERVICES, (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR
                THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY
                ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY
                CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE.
                COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY A THIRD PARTY THROUGH THE WEBSITE OR ANY HYPERLINKED WEBSITE
                OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL
                NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
                PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.{" "}
              </Typography>

              <Typography>
                <strong>LIMITATIONS OF LIABILITY</strong>
              </Typography>

              <Typography>
                IN NO EVENT SHALL COMPANY OR ITS DIRECTORS, EMPLOYEES, OR AGENTS
                BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA OR
                OTHER DAMAGES ARISING FROM YOUR USE OF THE WEBSITE OR COMPANY
                SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                HEREIN, COMPANY’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO COMPANY FOR THE
                COMPANY SERVICES DURING THE PERIOD OF THREE (3) MONTHS PRIOR TO
                ANY CAUSE OF ACTION ARISING.
              </Typography>

              <Typography>
                CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR
                LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                RIGHTS.
              </Typography>

              <Typography>
                IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL
                CODE SECTION 1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT
                EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH,
                IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
                WITH THE DEBTOR."
              </Typography>

              <Typography>
                <strong>INDEMNITY</strong>
              </Typography>

              <Typography>
                You agree to defend, indemnify and hold Company, its
                subsidiaries, and affiliates, and their respective officers,
                agents, partners and employees, harmless from and against, any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of your contributed content, use of the Company
                Services, and/or arising from a breach of this Agreement and/or
                any breach of your representations and warranties set forth
                above. Notwithstanding the foregoing, Company reserves the
                right, at your expense, to assume the exclusive defense and
                control of any matter for which you are required to indemnify
                Company, and you agree to cooperate, at your expense, with
                Company’s defense of such claims. Company will use reasonable
                efforts to notify you of any such claim, action, or proceeding
                which is subject to this indemnification upon becoming aware of
                it.
              </Typography>

              <Typography>
                <strong>NOTICES</strong>
              </Typography>

              <Typography>
                Except as explicitly stated otherwise, any notices given to
                Company shall be given by email to the address listed in the
                contact information below. Any notices given to you shall be
                given to the email address you provided during the registration
                process, or such other address as each party may specify. Notice
                shall be deemed to be given twenty-four (24) hours after the
                email is sent, unless the sending party is notified that the
                email address is invalid. We may also choose to send notices by
                regular mail.
              </Typography>

              <Typography>
                <strong>USER DATA</strong>
              </Typography>

              <Typography>
                Our Website will maintain certain data that you transfer to the
                Website for the purpose of the performance of the Company
                Services, as well as data relating to your use of the Company
                Services. Although we perform regular routine backups of data,
                you are primarily responsible for all data that you have
                transferred or that relates to any activity you have undertaken
                using the Company Services. You agree that Company shall have no
                liability to you for any loss or corruption of any such data,
                and you hereby waive any right of action against Company arising
                from any such loss or corruption of such data.
              </Typography>

              <Typography>
                <strong>ELECTRONIC CONTRACTING</strong>
              </Typography>

              <Typography>
                Your use of the Company Services includes the ability to enter
                into agreements and/or to make transactions electronically. YOU
                ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS CONSTITUTE YOUR
                AGREEMENT AND INTENT TO BE BOUND BY AND TO PAY FOR SUCH
                AGREEMENTS AND TRANSACTIONS. YOUR AGREEMENT AND INTENT TO BE
                BOUND BY ELECTRONIC SUBMISSIONS APPLIES TO ALL RECORDS RELATING
                TO ALL TRANSACTIONS YOU ENTER INTO RELATING TO THE COMPANY
                SERVICES, INCLUDING NOTICES OF CANCELLATION, POLICIES,
                CONTRACTS, AND APPLICATIONS. In order to access and retain your
                electronic records, you may be required to have certain hardware
                and software, which are your sole responsibility.{" "}
              </Typography>

              <Typography>
                <strong>ELECTRONIC SIGNATURES</strong>
              </Typography>

              <Typography>
                Users are allowed on Nostalgyc to transmit and receive valid
                electronic signatures in the United States under the Electronic
                Signatures in Global and National Commerce Act (E-Sign Act) of
                2000 and the Uniform Electronic Transactions Act (UETA) of 1999
                as adopted by individual states. Users’ signatures and
                identities are not authenticated on Nostalgyc.
              </Typography>

              <Typography>
                <strong>MISCELLANEOUS</strong>
              </Typography>

              <Typography>
                This Agreement constitutes the entire agreement between you and
                Company regarding the use of the Company Services. The failure
                of Company to exercise or enforce any right or provision of this
                Agreement shall not operate as a waiver of such right or
                provision. The section titles in this Agreement are for
                convenience only and have no legal or contractual effect. This
                Agreement operates to the fullest extent permissible by law.
                This Agreement and your account may not be assigned by you
                without our express written consent. Company may assign any or
                all of its rights and obligations to others at any time. Company
                shall not be responsible or liable for any loss, damage, delay
                or failure to act caused by any cause beyond Company's
                reasonable control. If any provision or part of a provision of
                this Agreement is unlawful, void or unenforceable, that
                provision or part of the provision is deemed severable from this
                Agreement and does not affect the validity and enforceability of
                any remaining provisions. There is no joint venture,
                partnership, employment or agency relationship created between
                you and Company as a result of this Agreement or use of the
                Website and Company Services. Upon Company’s request, you will
                furnish Company any documentation, substantiation or releases
                necessary to verify your compliance with this Agreement. You
                agree that this Agreement will not be construed against Company
                by virtue of having drafted them. You hereby waive any and all
                defenses you may have based on the electronic form of this
                Agreement and the lack of signing by the parties hereto to
                execute this Agreement.
              </Typography>

              <Typography>
                <strong>CONTACT US</strong>
              </Typography>

              <Typography>
                In order to resolve a complaint regarding the Company Services
                or to receive further information regarding use of the Company
                Services, please contact Company as set forth below:
              </Typography>

              <Typography>Email: support@nostalgyc.com</Typography>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Terms;

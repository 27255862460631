import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import InpForm from "./inputForm";
import Gradient from "rgt";
const useStyles = makeStyles((theme) => ({
  textContainer: {
    padding: "3.5rem 5rem",
    background: "white",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1.5rem",
      paddingBottom: "-20px",
    },
  },
  pHeading: {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "rgba(38, 70, 83, 1)",
  },
  sHeading: {
    color: "rgba(11, 18, 35, 1)",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.3rem",
    },
  },
}));

const EarlyAccessForm = ({ refLink, moveto, loading }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      md={8}
      alignItems="center"
      wrap="nowrap"
      className={classes.textContainer}
    >
      <Grid item className={classes.headingContainer}>
        <Typography className={classes.pHeading}>
          <Gradient dir="left-to-right" from="#007CF0" to="#00DFD8">
            Join {""}
          </Gradient>
          <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
            the{" "}
          </Gradient>
          <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
            Waitlist {""}
          </Gradient>{" "}
        </Typography>
        <Typography className={classes.sHeading}>
          Get early access of Nostalgyc app
        </Typography>
      </Grid>
      <Grid item>
        <InpForm refLink={refLink} loading={loading} moveto={moveto} />
      </Grid>
    </Grid>
  );
};

export default EarlyAccessForm;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography } from "@material-ui/core";
import Gradient from "rgt";
const data = [
  {
    primary: "Recommended topics and videos",
    secondary:
      "Use recommended topics to request videos from others. Reminisce your memories by watching nostalgic videos",
  },
  {
    primary: "Create request and send it to others or to yourself",
    secondary:
      "Request videos about your favourite place, street, toy, event, activity or anything that is important to you and can make you feel nostalgic.",
  },
  {
    primary: "Keep videos and topics private (or public)",
    secondary:
      "You can keep your videos and topics private to yourself or share those just with your friends or can open those to public to view and share",
  },
  {
    primary: "Provide rewards for people fulfilling your requests",
    secondary:
      "Option to specify and send rewards to people who are fulfilling your video requests ",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(44, 178, 138, 1)",
    padding: "5.75rem 8.75rem 7rem",
    [theme.breakpoints.down("md")]: {
      padding: "4rem 4rem ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 2rem ",
    },
  },
  trending: {
    paddingBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "3rem",
      textAlign: "center",
    },
    color: "rgba(253, 248, 229, 1)",
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "0",
    },
  },
  avatar: {
    backgroundColor: "rgba(234, 187, 0, 1)",
    marginRight: "3rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      marginBottom: "1rem",
    },
    width: "50px",
    height: "50px",
  },
  avatarText: {
    fontWeight: "600",
    fontSize: "1.5rem",
    lineHeight: "3.75rem",
  },
  primaryText: {
    color: "white",
    ...theme.typography.introText,
    fontWeight: "600",
    fontSize: "1.75rem",
    lineHeight: "2.75rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
  },
  secondaryText: {
    color: "white",
    fontSize: ".95rem",
    lineHeight: "1.5rem",
  },
  heading: {
    ...theme.typography.introText,
    fontWeight: 800,
    fontSize: "2.75rem",
    lineHeight: "3.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
      lineHeight: "2.25rem",
    },
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
    },
  },
}));

const HowNostalgicWorks = () => {
  const classes = useStyles();
  const cards = data.map((card, index) => {
    return (
      <Grid
        key={index}
        item
        container
        wrap="nowrap"
        className={classes.container}
        alignItems={"center"}
      >
        <Grid item>
          <Avatar className={classes.avatar}>
            <Typography className={classes.avatarText}>{index + 1}</Typography>
          </Avatar>
        </Grid>
        <Grid item>
          <Typography className={classes.primaryText}>
            {card.primary}
          </Typography>
          <Typography className={classes.secondaryText}>
            {card.secondary}
          </Typography>
        </Grid>
      </Grid>
    );
  });
  return (
    <div className={classes.root}>
      <div className={classes.trending}>
        <Typography className={classes.heading}>
          <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
            Features
          </Gradient>{" "}
        </Typography>
      </div>
      <Grid
        className={classes.containerGrid}
        spacing={6}
        direction="column"
        container
        justify="center"
        alignItems="center"
      >
        {cards}
      </Grid>
    </div>
  );
};

export default HowNostalgicWorks;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import imgSrc from "../../../assets/img/collage.jpg";
import AmbassadorCard from "./ambassadorCards.jsx";
import img1 from "../../../assets/img/image 11.png";
import img2 from "../../../assets/img/image 12.png";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
const data = [
  {
    imgSrc: img1,
    pHeading: "Early Access",
    pdesc:
      "Be the first to get notified about beta launch of Nostagyc website and app.  ",
  },
  {
    imgSrc: img2,
    pHeading: "Photo Collage",
    pdesc:
      "Get a digital photo collage of all friends who join through your referral link",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(241, 244, 241, 1)",
    padding: "4rem 5rem 3.5rem",
    [theme.breakpoints.down("md")]: {
      padding: "3rem 0rem 3rem",
    },
  },
  trending: {
    textAlign: "center",
    paddingBottom: "5.5rem",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "2.5rem",
    },
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {},
  },
  typography: {
    color: "white",
    fontFamily: theme.typography.fontFamily,
    lineHeight: "24px",
    textDecoration: "none",
  },
  heading: {
    color: theme.palette.primary.main,
    ...theme.typography.introText,
    lineHeight: "3.75rem",
    fontSize: "2.25rem",
    fontWeight: 600,
  },
  subHeading: {
    color: "black",
    fontSize: ".9rem",
    lineHeight: "1.25rem",
    margin: "0px 12.95rem",
    [theme.breakpoints.down("md")]: {
      margin: "0px 3rem",
    },
  },
  avatar: {
    backgroundColor: " rgba(234, 187, 0, 1)",
    marginRight: "3rem",
    width: "50px",
    height: "50px",
  },
  avatarText: {
    fontWeight: "800",
    fontSize: "2rem",
    lineHeight: "3.75rem",
  },
  primaryText: {
    color: "white",
    fontWeight: "600",
    fontSize: "1.75rem",
    lineHeight: "2.75rem",
  },
  secondaryText: {
    color: "white",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  button: {
    marginTop: "2rem",
    marginBottom: "2.5rem",
    background: "linear-gradient(90deg, #FF827F 5.38%, #FEE1A7 91.77%)",
    borderRadius: "40px",
    padding: "1.125rem 4.25rem",
  },
  cardsContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonText: {
    fontWeight: 400,
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    texTransform: "uppercase",
    color: "rgba(11,18,35,1)",
  },
  cardContainerIn: {
    margin: "0.5rem",
  },
}));

const BecomeAmbassador = () => {
  const classes = useStyles();
  const cards = data.map((card, index) => {
    return (
      <Grid className={classes.cardContainerIn} key={index} item>
        <AmbassadorCard
          index={index}
          imgSrc={card.imgSrc}
          pdesc={card.pdesc}
          pHeading={card.pHeading}
        />
      </Grid>
    );
  });
  return (
    <div className={classes.root}>
      <div className={classes.trending}>
        <Typography variant="h3" className={classes.heading}>
          Become a Nostalgyc ambassador!
        </Typography>
        <Typography className={classes.subHeading}>
          Join the waitlist now to be part of our beta launch and become an
          ambassador. Nostalgyc ambassador will get a digital photo collage of
          all friends and family members who join through their referral link
        </Typography>
        <Button className={classes.button} component={Link} to={"/join"}>
          <Typography className={classes.buttonText}>Count me In!</Typography>
        </Button>
      </div>
      <Grid className={classes.containerGrid} container justify="center">
        <Grid item container className={classes.cardsContainer} lg={7}>
          {cards}
        </Grid>
        <Grid item container direction="column" xs={12} lg={5}>
          <LazyLoad>
            <img alt="collage" style={{ width: "100%" }} src={imgSrc} />
            <Typography style={{ padding: "1rem" }}>
              Photo Collage Example: Final appearance of the photo collage may
              vary and may be different for different users. Photo collage will
              depend upon profile pictures submitted by people who joined using
              your referral link. Limited availability.{" "}
            </Typography>
          </LazyLoad>
        </Grid>
      </Grid>
    </div>
  );
};

export default BecomeAmbassador;

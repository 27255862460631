import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import EarlyAccessForm from "./earlyAccessForm";
import ShareWithFriendsForm from "./ShareWithFriends";
import LeftBanner from "./LeftBannerView";
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(241, 244, 241, 1)",
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.7)",
  },
  textContainer: {
    height: "100%",
    padding: "3.5rem 5rem",
    background: "white",
    textAlign: "center",
  },
  pHeading: {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "rgba(38, 70, 83, 1)",
  },
  sHeading: {
    color: "rgba(11, 18, 35, 1)",
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  cardContainerIn: {
    margin: "0.5rem 1rem",
  },
  headingContainer: {
    marginBottom: "1rem",
  },
}));

const EarlyAccess = ({
  refLink,
  moveto,
  statusfunc,
  already,
  query,
  loading,
  invalidLink,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className={classes.containerGrid} container justify="center">
        <LeftBanner />
        {invalidLink ? (
          <Grid
            style={{ background: "white", padding: "50px" }}
            item
            container
            direction="column"
            alignItems="center"
            justify="center"
            md={8}
          >
            <Typography variant="h4" align="center">
              Your Referral Link Is Invalid
            </Typography>
          </Grid>
        ) : (
          <>
            {!already ? (
              <>
                {refLink === "" ? (
                  <EarlyAccessForm
                    loading={loading}
                    refLink={refLink}
                    moveto={moveto}
                  />
                ) : (
                  <ShareWithFriendsForm
                    refLink={refLink}
                    statusfunc={statusfunc}
                  />
                )}
              </>
            ) : (
              <Grid
                style={{ background: "white" }}
                item
                container
                direction="column"
                alignItems="center"
                justify="center"
                md={8}
              >
                {query === "" ? (
                  <Typography variant="h4" align="center">
                    You email is already registered.
                  </Typography>
                ) : (
                  <Typography variant="h4" align="center">
                    Thanks for joining the waitlist. Please share Nostalgyc link
                    with your friends.
                  </Typography>
                )}
              </Grid>
            )}
          </>
        )}

        {/* <StatusCheck/> */}
      </Grid>
    </div>
  );
};

export default EarlyAccess;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import WhyJoinCard from "./whyJoinCard";
import AmbassadorCard from "./ambassadorCards.jsx";
import img1 from "../../../assets/img/image 11.png";
import img2 from "../../../assets/img/image 12.png";
import Gradient from "rgt";
const data = [
  {
    imgSrc: img2,
    pHeading: "Know [so-and-so] better ",
    pdesc:
      "Have you ever wondered what your parents were like when they were in high school? Do you want to know or understand your friends better?  Use Nostalgyc recommendations to request answers of these questions",
  },
  {
    imgSrc: img1,
    pHeading: "Reconnect with friends and family",
    pdesc:
      "Are you losing touch with your family and friends? Ae you looking for reasons to contact your friends. Discover triggers to reconnect with people at more personal level than the usual social media sites.",
  },
  {
    imgSrc: img2,
    pHeading: "Reminisce memories from your past",
    pdesc:
      "Share videos or request videos about your favorite place, street, toy, event, activity or anything that make you feel nostalgic.",
  },
  {
    imgSrc: img1,
    pHeading: "Find recommendation to create memories",
    pdesc:
      "Create memories with help of topics that are recommended to you based on your interests. Save memories from today for your future self.",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(234, 187, 0, 0.08)",
    padding: "3.75rem 4.75rem 5rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.75rem 3.75rem 3rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2.75rem 2.75rem 2.75rem",
    },
  },
  trending: {
    textAlign: "center",
    paddingBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "1rem",
    },
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {},
  },
  cardsContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
    },
  },
  heading: {
    ...theme.typography.introText,
    fontWeight: 600,
    fontSize: "2.75rem",
    color: "rgba(11, 18, 35, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  subHeading: {
    fontSize: "1.5rem",
    fontWeight: "300",
    marginTop: "2rem",
    ...theme.typography.introText,
    // marginRight:"14rem",
    marginBottom: "2.75rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem",
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
      fontSize: "1rem",
      marginTop: "1rem",
    },
  },
  cardContainerIn: {
    margin: "0.2rem",
  },
}));

const WhyNostalgic = () => {
  const classes = useStyles();
  const cards = data.map((card, index) => {
    return (
      <Grid className={classes.cardContainerIn} key={index} item>
        <AmbassadorCard
          index={index}
          imgSrc={card.imgSrc}
          pdesc={card.pdesc}
          pHeading={card.pHeading}
        />
      </Grid>
    );
  });

  // const cards = data.map((card, index) => {
  //   return (
  //     <Grid item key={index}>
  //       <WhyJoinCard
  //         pdesc={card.pdesc}
  //         pHeading={card.pHeading}
  //         sHeading={card.sHeading}
  //         sdesc={card.sdesc}
  //         ind={index}
  //       />
  //     </Grid>
  //   );
  // });
  return (
    <div className={classes.root}>
      <div className={classes.trending}>
        <Typography className={classes.heading}>
          <Gradient dir="left-to-right" from="#007CF0" to="#00DFD8">
            Why {""}
          </Gradient>
          <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
            Join{" "}
          </Gradient>
          <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
            Nostalgyc? {""}
          </Gradient>{" "}
        </Typography>
        <Typography className={classes.subHeading}>
          We know that personal connections are the most important. We launched
          Nostalgyc to make it easy to create, maintain and grow those personal
          connections. Nostalgyc helps you and your friends, significant other,
          parents, grandparents, and all people important to you, connect with
          one another again by capturing, storing, and sharing their life
          experiences in a fun way.
        </Typography>
      </div>
      <Grid className={classes.containerGrid} container justify="center">
        <Grid item container className={classes.cardsContainer}>
          {cards}
        </Grid>
      </Grid>
    </div>
  );
};

export default WhyNostalgic;

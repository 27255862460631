import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import logo from "../../../assets/img/logo.png";
import location from "../../../assets/img/location.png";
import BannerImg from "../../../assets/img/bannerImg.png";
import LazyLoad from "react-lazyload";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(241, 244, 241, 1)",
  },
  containerGrid: {
    background: "rgba(0,0,0,0.7)",
  },
  bannerContainer: {
    backgroundImage: `url(${BannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }
}));

const LeftBanner = (props) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.bannerContainer}
      item
      container
      direction="column"
      alignItems="center"
      justify="center"
      md={4}
    >
      <LazyLoad>
        <img alt="location logo" src={location} />
      </LazyLoad>
      <LazyLoad>
        <img alt="website logo" className={classes.logo} src={logo} />
      </LazyLoad>
    </Grid>
  );
};

export default LeftBanner;

import React from "react";
//Card
import Snackbar from '@material-ui/core/Snackbar';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

import { BaseURL } from "../../../Utils/config";
//Tabs
import { withStyles } from "@material-ui/core/styles";
import { AddIcon } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
// import InfoIcon from '@material-ui/icons/Info';
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    "&:hover": {
      color: red[800],
    },
  },
  cardName: {
    padding: 0,
  },
  cardHeader: {
    textalign: "center",
    align: "center",
    backgroundColor: "white",
  },
  input: {
    display: "none",
  },
  title: {
    color: blue[800],
    fontWeight: "bold",
    fontFamily: "Montserrat",
    align: "center",
  },
  button: {
    boxSizing: "border-box",
    // color: "#43778C",
    background: "transparent",
    margin: 10,
    height: "6rem",
    width: "6rem",
    cursor: "pointer",
    border: "2px dashed #AAAAAA",
  },
  secondaryButton: {
    color: "gray",
    margin: 10,
  },
  typography: {
    margin: theme.spacing.unit * 2,
    backgroundColor: "default",
  },
  avatar: {
    // height: "6rem",
    // width: "6rem"
    color: theme.palette.primary.main,
  },
  imgText: {
    marginLeft: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  imgTextContent: {
    textAlign: "left",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  uploadImgContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

class ImageUploadCard extends React.Component {
  state = {
    mainState: "initial", // initial, search, gallery, uploaded
    imageUploaded: 0,
    selectedFile: this.props.profile_pic,
    loading: false,
    open1 :false,
    open2 : false
  };

  handleUploadClick = (event) => {
    this.setState({ loading: !this.state.loading });
    console.log();
    const file = event.target.files[0];
    const headers = new Headers();
    headers.append("Accept", "application/json");
    var body = new FormData();
    body.append("file", file);

    const payload = {
      method: "POST",
      headers: headers,
      body: body,
    };

    fetch(`${BaseURL}api/v1/uploadpic/${this.props.query}/`, payload)
      .then((response) => {
        console.log(response);
        if (response.status === 400 || response.status===413 || response.status === 500) {
          if(response.status === 413){
            this.setState({
              open2 : true,
            })
            return;
          }
          alert("Image not Changes try again");
          return;
        }
        return response.json();
      })
      .then((response) => {
        console.log(response);
        this.setState({
          selectedFile: response.status,
          loading: false,
          open1: true,
        });
      })
      .catch((error) => this.setState({ error, loading: false }));
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card elevation={0} className={this.props.cardName}>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                wrap="nowrap"
              >
                {this.state.loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={this.handleUploadClick}
                    />
                    <Grid
                      item
                      container
                      wrap="nowrap"
                      alignItems="center"
                      className={classes.uploadImgContainer}
                    >
                      <label htmlFor="contained-button-file">
                        <Avatar
                          variant="square"
                          className={[classes.button]}
                          src={this.state.selectedFile}
                        >
                          <AddIcon className={classes.avatar}></AddIcon>
                        </Avatar>
                      </label>
                      <Grid item className={classes.imgText}>
                        <Typography className={classes.imgTextContent}>
                          Upload profile picture*
                        </Typography>
                        <Typography className={classes.imgTextContent}>
                          Please upload a half body portrait on colored
                          background for a better collage
                        </Typography>
                        {/* <Typography>
                          <InfoIcon/> Upload Live Picture
                        </Typography> */}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open1}
            autoHideDuration={6000}
            onClose={() => {this.setState({open1 : false})}}
            message="Profile Image Upload Success!"
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.open2}
            autoHideDuration={6000}
            onClose={() => {this.setState({open2 : false})}}
            message="Image Should be less than 10mb!"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);

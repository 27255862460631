// import React from 'react';
// import { Button, Input } from '@material-ui/core'

// const StatusView = (props) => {
//     return (
//         <div style={{flex:1,flexDirection:'column'}}>
//             {JSON.stringify(props.data)}
//         </div>
//     );
// }

// export default StatusView;
// // a33a4538-0bcc-48f6-b9c4-c8aa4176986f
// // http//localhost:3000?5df3b759-b863-40f2-85d5-54c76199de0f

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import StatusCheck from "../../Join/View/statusCheck";
import LeftBanner from "../../Join/View/LeftBannerView";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(241, 244, 241, 1)",
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.7)",
  },
  textContainer: {
    height: "100%",
    padding: "3.5rem 5rem",
    background: "white",
    textAlign: "center",
  },
  pHeading: {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "rgba(38, 70, 83, 1)",
  },
  sHeading: {
    color: "rgba(11, 18, 35, 1)",
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  cardContainerIn: {
    margin: "0.5rem 1rem",
  },
  headingContainer: {
    marginBottom: "1rem",
  },
}));

const StatusView = ({ data, query }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className={classes.containerGrid} container justify="center">
        <LeftBanner />
        <StatusCheck query={query} data={data} />
      </Grid>
    </div>
  );
};

export default StatusView;

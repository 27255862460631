import * as React from "react";
import {NavBar, Footer} from "../Home";
import {BaseURL} from "../../Utils/config"
import StatusView from "./View/statusView";
import StatusViewD from "./View/statusViewdummy";

const FAKE_DATA = {first_name:"",last_name:"",email:"",joinedby:[],profile_pic:"", ref_link:""}

export const StatusConnector = (props) => {
    const [data, setData] = React.useState(FAKE_DATA)
    const [stalink, setSL] = React.useState("")

    React.useState(() => {
        const query = props.location.search
        setSL(query.slice(1,))
        if (query === "") {
            alert("Link Is Not Provided Correctly")
            return;
        }

        const payload = {
            method: "GET",
        }

        fetch(`${BaseURL}api/v1/status/${query.slice(1,)}/`, payload)
        .then(response => {
            if(response.status === 404){
                return 
            }else{
                return response.json()
            }
        })
        .then((response) => {
            if(response){
                setData(response)
            }
        }).catch((e) => {
            console.log(e)
        })
    })
    return(
        <div> 
            <NavBar/>
                {data === FAKE_DATA  ? <StatusViewD query={stalink} data={FAKE_DATA}/> :
                    <StatusView query={stalink} data={data}/>
                }
            <Footer />
        </div>
    )
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import CopyButton from "../../../assets/img/copyButton.png";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    height: "100%",
    padding: "3.5rem 17rem",
    background: "white",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem 1.5rem",
    },
  },
  pHeading: {
    fontWeight: "600",
    fontSize: "2rem",
    lineHeight: "3rem",
    color: "rgba(38, 70, 83, 1)",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7rem",
      lineHeight: "2rem",
      textAlign: "center",
    },
  },
  sHeading: {
    color: "rgba(11, 18, 35, 1)",
    fontSize: "1.5rem",
    lineHeight: "2rem",
    textAlign: "justify",
  },
  cardContainerIn: {
    margin: "0.5rem 1rem",
  },
  headingContainer: {
    marginBottom: "1rem",
  },
  formContainer: {
    // padding:"0rem 10rem"
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
      lineHeight: "1.4rem",
      textAlign: "center",
    },
  },
  shareText: {
    fontSize: "1rem",
    lineHeight: "1.75rem",
    fontWeight: 600,
    marginTop: "1.5rem",
    marginBottom: "1.25rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0rem",
    },
  },
  iconsContainer: {
    marginBottom: "1.75rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0rem",
    },
  },
  checkStatus: {
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    marginTop: "8.75rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
}));

const ShareWithFriendsForm = ({ refLink, statusfunc }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      md={8}
      alignItems="center"
      wrap="nowrap"
      className={classes.textContainer}
    >
      <Grid item className={classes.headingContainer}>
        <Typography className={classes.pHeading}>
          Thanks for joining. Don’t leave your friends and family behind!
        </Typography>
        {/* <Typography className={classes.sHeading}>
          Give a chance to your friend to get a digital photo collage with you
          and with their friends and family members who join through their
          referral link
        </Typography> */}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justify="center"
        direction="column"
        className={classes.formContainer}
      >
        <Typography className={classes.shareText}>
          Share with social media
        </Typography>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          className={classes.iconsContainer}
        >
          <IconButton>
            <WhatsappShareButton
              url={refLink}
              title={
                "Thought you might be interested in Nostalgyc. Nostalgyc help you discover and request videos from locations which make you feel nostlagic. Use my unique link to join the waitlist."
              }
              separator=":: "
            >
              <WhatsappIcon round size="30" />
            </WhatsappShareButton>
          </IconButton>
          <IconButton>
            <FacebookShareButton
              url={refLink}
              quote={
                "Thought you might be interested in Nostalgyc. Nostalgyc help you discover and request videos from locations which make you feel nostlagic. Use my unique link to join the waitlist."
              }
            >
              <FacebookIcon round size="30" />
            </FacebookShareButton>{" "}
          </IconButton>
          <IconButton>
            <TwitterShareButton
              url={refLink}
              title={
                "Thought you might be interested in Nostalgyc. Nostalgyc help you discover and request videos from locations which make you feel nostlagic. Use my unique link to join the waitlist."
              }
            >
              {" "}
              <TwitterIcon round size="30" />
            </TwitterShareButton>
          </IconButton>
          <IconButton>
            <FacebookMessengerShareButton
              url={refLink}
              title={
                "Thought you might be interested in Nostalgyc. Nostalgyc help you discover and request videos from locations which make you feel nostlagic. Use my unique link to join the waitlist."
              }
            >
              {" "}
              <FacebookMessengerIcon round size="30" />
            </FacebookMessengerShareButton>
          </IconButton>
        </Grid>
        <Grid item container alignItems="center" justify="center" wrap="nowrap">
          <TextField
            id="outlined-read-only-input"
            // label="Read Only"
            defaultValue={refLink}
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
            fullWidth
          />
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(refLink);
            }}
          >
            <img alt="copy button" style={{ padding: "0" }} src={CopyButton} />
          </IconButton>
        </Grid>
        {/* <Typography className={classes.checkStatus}>
          To check status and upload profile picture now,{" "}
          <Button onClick={statusfunc}>click here</Button>. You can also check
          your email for these links. The email could be in the "Promotions" tab
          of your inbox.
        </Typography> */}
      </Grid>

      {/* <Grid item container alignItems="center" justify="center">
                        {cards}
                    </Grid> */}
      {/* <img style={{width:"100%"}} src={imgSrc}/>
                    <Typography>Photo Collage: For representation purpose only. Final appearance of the photo collage may vary and may be different for different users. Photo collage will depend upon profile picture submitted by people who joined using your referral link. Limited availability. </Typography> */}
    </Grid>
  );
};

export default ShareWithFriendsForm;

import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import sanityClient from "../../../client.js";
import Gradient from "rgt";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(234, 187, 0, 0.08)",
    padding: "3.75rem 4.75rem 5rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.75rem 3.75rem 3rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2.75rem 2.75rem 2.75rem",
    },
  },
  trending: {
    textAlign: "center",
    paddingBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "1rem",
    },
  },
  containerGrid: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {},
  },
  cardsContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
    },
  },
  heading: {
    ...theme.typography.introText,
    fontWeight: 600,
    fontSize: "2.75rem",
    color: "rgba(11, 18, 35, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  subHeading: {
    fontSize: "1.5rem",
    fontWeight: "300",
    marginTop: "2rem",
    ...theme.typography.introText,
    // marginRight:"14rem",
    marginBottom: "2.75rem",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem",
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
      fontSize: "1rem",
      marginTop: "1rem",
    },
  },
  cardContainerIn: {
    margin: "0.2rem",
  },
}));
// const BlogView = () => {
//   const classes = useStyles();
//   const cards = data.map((card, index) => {
//     return (
//       <Grid className={classes.cardContainerIn} key={index} item>
//         <AmbassadorCard
//           index={index}
//           imgSrc={card.imgSrc}
//           pdesc={card.pdesc}
//           pHeading={card.pHeading}
//         />
//       </Grid>
//     );
//   });
//   return (
//     <div className={classes.root}>
//       <div className={classes.trending}>
//         <Typography variant="h3" className={classes.heading}>
//           Blog
//         </Typography>
//         {/* <Typography className={classes.subHeading}>
//           Join the waitlist now to be part of our beta launch and become an
//           ambassador. Nostalgyc ambassador will get a digital photo collage of
//           all friends and family members who join through their referral link
//         </Typography>
//         <Button className={classes.button} component={Link} to={"/join"}>
//           <Typography className={classes.buttonText}>Count me In!</Typography>
//         </Button> */}
//       </div>
//       {/* <Grid className={classes.containerGrid} container justify="center">
//         <Grid item container className={classes.cardsContainer} lg={7}>
//           {cards}
//         </Grid>
//         <Grid item container direction="column" xs={12} lg={5}>
//           <LazyLoad>
//             <img alt="collage" style={{ width: "100%" }} src={imgSrc} />
//             <Typography style={{ padding: "1rem" }}>
//               Photo Collage Example: Final appearance of the photo collage may
//               vary and may be different for different users. Photo collage will
//               depend upon profile pictures submitted by people who joined using
//               your referral link. Limited availability.{" "}
//             </Typography>
//           </LazyLoad>
//         </Grid>
//       </Grid> */}
//     </div>
//   );
// };

// export default BlogView;

export default function AllPosts() {
  const classes = useStyles();
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.trending}>
        <Typography className={classes.heading}>
          <Gradient dir="left-to-right" from="#007CF0" to="#00DFD8">
            Nostalgyc {""}
          </Gradient>
          <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
            Blog{" "}
          </Gradient>
        </Typography>
        {/* <h2 className="text-5xl flex justify-center">Nostalgyc Blogs</h2> */}
        <h3 className="text-lg text-gray-600 flex justify-center mb-12"></h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {allPostsData &&
            allPostsData.map((post, index) => (
              <Link to={"/blog/" + post.slug.current} key={post.slug.current}>
                <span
                  className="block h-64 relative rounded shadow leading-snug bg-white
                      border-l-8 border-green-400"
                  key={index}
                >
                  <img
                    className="w-full h-full rounded-r object-cover absolute"
                    src={post.mainImage.asset.url}
                    alt=""
                  />
                  <span
                    className="block relative h-full flex justify-end items-end pr
                      -4 pb-4"
                  >
                    <h2
                      className="text-gray-800 text-lg font-bold px-3 py-4 bg-red-700
                        text-red-100 bg-opacity-75 rounded"
                    >
                      {post.title}
                    </h2>
                  </span>
                </span>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
